import moment from 'moment';

export function formatDate(dateObj) {
	let month = '' + (dateObj.getMonth() + 1);
	let day = '' + dateObj.getDate();
	let year = dateObj.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [month, day, year].join('/');
}

export function formatTime(dateObj) {
	let h = '' + dateObj.getHours();
	let m = '' + dateObj.getMinutes();

	if (h.length < 2) h = '0' + h;
	if (m.length < 2) m = '0' + m;

	return h + ':' + m;
}

export function formatDateTime(dateTimeObj) {
	let month = '' + (dateTimeObj.getMonth() + 1);
	let day = '' + dateTimeObj.getDate();
	let year = dateTimeObj.getFullYear();
	let hour = '' + dateTimeObj.getHours();
	let minutes = '' + dateTimeObj.getMinutes();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;
	if (hour.length < 2) hour = '0' + hour;
	if (minutes.length < 2) minutes = '0' + minutes;

	return [day, month, year].join('/') + '-' + hour + ':' + minutes;
}

export function calculateDuration(startedAt, endedAt) {
	var delta = Math.abs(startedAt - endedAt) / 1000;

	var days = Math.floor(delta / 86400);
	delta -= days * 86400;

	var hours = Math.floor(delta / 3600) % 24;
	delta -= hours * 3600;

	var minutes = Math.floor(delta / 60) % 60;
	delta -= minutes * 60;

	var seconds = Math.round(delta % 60);

	if (hours < 10) hours = '0' + hours;
	if (minutes < 10) minutes = '0' + minutes;
	if (seconds < 10) seconds = '0' + seconds;

	return hours + ':' + minutes + ':' + seconds;
}

export function durationUntilNow(utcDateTime) {
	const k = moment(moment.utc().format()).diff(moment.utc(utcDateTime));
	const days = Math.floor(k / 1000 / 86400);
	const f = days > 0 ? `${days}[d] HH:mm:ss` : 'HH:mm:ss';
	return moment.utc(moment(moment.utc().format()).diff(moment.utc(utcDateTime))).format(f);
}

export function utcToLocalTime(date) {
	const stillUtc = moment.utc(date).toDate();
	const localDate = moment(stillUtc);
	return localDate;
}

export const getDateDifference = (start, end) => {
	const startDate = moment(start).startOf('day');
	const endDate = moment(end).startOf('day');
	return moment.duration(endDate.diff(startDate)).asDays();
};

export const secondsToHHMMSS = seconds => {
	const HH = `${Math.floor(seconds / 3600)}`.padStart(2, '0');
	const MM = `${Math.floor(seconds / 60) % 60}`.padStart(2, '0');
	const SS = `${Math.floor(seconds % 60)}`.padStart(2, '0');
	return [HH, MM, SS].join(':');
};
