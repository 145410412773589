import { gatewayApi, APP_CONFIG } from '../constants/global-variables';

export function getDeviceConferencesByLevel(level, id, pageSize = 10, pageIndex = 0, start, end) {
	let params = {
		level: level,
		id: id || APP_CONFIG.companyId,
		pageSize: pageSize,
		pageIndex: pageIndex,
		start: start ? new Date(start).getTime() / 1000 : undefined,
		end: end ? new Date(end).getTime() / 1000 : undefined,
	};

	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1.2/conferences/organization-devices/${APP_CONFIG.companyId}`, {
				params: params,
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getDeviceOngoingConferencesByLevel(level, id, pageSize = 10, pageIndex = 0) {
	let params = {
		level: level,
		id: id || APP_CONFIG.companyId,
		pageSize: pageSize,
		pageIndex: pageIndex,
	};

	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/conferences/ongoing/organization-devices/${APP_CONFIG.companyId}`, {
				params: params,
			})
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export const startExportLogsTask = async ({ level, id, start, end }) => {
	try {
		let params = {
			level: level,
			id: id || APP_CONFIG.companyId,
			start: Math.round(new Date(start).getTime() / 1000),
			end: Math.round(new Date(end).getTime() / 1000),
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
		};

		const { data } = await gatewayApi.get(`v1/tasks/companies/${APP_CONFIG.companyId}/conferences`, { params });
		return data;
	} catch (error) {
		return { error };
	}
};

export const exportCallLogs = async ({ start, end, healthSystemId, regionId, hospitalId, departmentId, floorId, roomId }) => {
	try {
		const params = {
			start,
			end,
			healthSystemId,
			regionId,
			hospitalId,
			departmentId,
			floorId,
			roomId,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		};
		const { data } = await gatewayApi.get(`/v1.1/tasks/companies/${APP_CONFIG.companyId}/conferences`, { params });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPastCallLogs = async ({ start, end, healthSystemId, regionId, hospitalId, departmentId, floorId, roomId, pageSize, pageIndex }) => {
	try {
		const params = {
			start,
			end,
			healthSystemId,
			regionId,
			hospitalId,
			departmentId,
			floorId,
			roomId,
			pageSize,
			pageIndex,
		};
		const { data } = await gatewayApi.get(`/v1/conferences/past/organization/${APP_CONFIG.companyId}`, { params });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNurseCallsForDashboard = async ({ healthSystemId, regionId, hospitalId, nurseId, start, end }) => {
	try {
		const params = {
			healthSystemId,
			regionId,
			hospitalId,
			nurseId,
			start,
			end,
		};
		const { data } = await gatewayApi.get(`/v1/organizations/${APP_CONFIG.companyId}/callstats/nurses/call-count`, { params });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getKpisForDashboard = async ({ healthSystemId, regionId, hospitalId, nurseId, start, end }) => {
	try {
		const params = {
			healthSystemId,
			regionId,
			hospitalId,
			nurseId,
			start,
			end,
		};
		const { data } = await gatewayApi.get(`/v1/organizations/${APP_CONFIG.companyId}/callstats/kpis`, { params });
		return data;
	} catch (error) {
		return { error };
	}
};
